import React, { useState } from "react"

import { Link } from "gatsby"

const Header = props => {
  const [showMobileNav, setShowMobileNav] = useState(false)

  function toogleMobileNav() {
    if (showMobileNav) {
      setShowMobileNav(false)
    } else {
      setShowMobileNav(true)
    }
  }

  return (
    <header id="header" className="header">
      <meta name="og:title" content="Jobs at the Woodland Trust" />
      <meta name="title" content="Jobs at the Woodland Trust" />
      <div id="scriptsWrap"></div>
      <div className="navbar top-links__wrapper d-none d-lg-flex">
        <div className="container d-flex justify-content-between top-links__wrapper__container">
          <a
            className="navbar-brand d-md-none"
            href="https://www.woodlandtrust.org.uk/"
          >
            <img
              loading="lazy"
              src="https://cdn1.accropress.com/Woodland%20Trust/images/layout/wt-logo-small.svg"
              height="38"
              alt="Woodland Trust"
            />
          </a>
          <ul className="d-none d-md-inline-flex mr-auto header__top-links header__top-links--job-vacancies">
            <li className="top-text">
              <Link to="/">Job Vacancies</Link>
            </li>
            {/* <li className="bottom-link">
            <img
            loading="lazy"
              src="https://cdn1.accropress.com/Woodland%20Trust/images/left-arrow.svg"
              className="d-none d-md-inline-flex back-icon"
              alt=""
              role="presentation"
            />
              <a href="https://www.woodlandtrust.org.uk/">Back to woodlandtrust.org.uk</a>
            </li> */}
          </ul>
          <a
            className="navbar-brand d-none d-md-flex"
            href="https://www.woodlandtrust.org.uk/"
          >
            <img
              loading="lazy"
              src="https://cdn1.accropress.com/Woodland%20Trust/images/layout/wt-logo-lg.svg"
              height="72"
              alt="Woodland Trust"
            />
          </a>
          <Link to="/job-alerts/" className="nav-action-sm" role="button">
            Get job alerts
          </Link>
        </div>
      </div>
      <div id="wtoverlay-sm" className="page-overlay d-lg-none"></div>
      <div
        className="navbar navbar-tablet-mobile top-links__wrapper d-lg-none p-0"
        style={{ minHeight: "64px" }}
      >
        <a href="https://www.woodlandtrust.org.uk/" className="mobile-nav-logo">
          <img
            loading="lazy"
            src="https://cdn1.accropress.com/Woodland%20Trust/images/wt%20logo%20mobile%20dark.svg"
            className="h-100"
            alt="Woodland Trust"
          />
        </a>
        <span className="top-text">
          <Link to="/">Job Vacancies</Link>
        </span>
        <Link
          to="/job-alerts/"
          className="job-alerts-button nav-action-sm nav-action-sm-mobile"
          role="button"
        >
          Get job alerts
        </Link>
      </div>
    </header>
  )
}

export default Header
