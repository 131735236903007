import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Footer = () => (
  <footer className="footer-container">
    <div className="footer">
      <div className="container">
        <div className="footer__logo">
          <a href="https://www.woodlandtrust.org.uk/">
            <img
              loading="lazy"
              className="w-100"
              src="https://cdn1.accropress.com/Woodland%20Trust/images/wt-logo-dark.svg"
              alt="Woodland Trust home page"
            />
          </a>
        </div>
        <div className="signup-action">
          <div className="signup-action__text">
            <p>
              Keep in touch with the nature you love without having to leave the
              house.
            </p>
          </div>
          <div className="signup-action__button">
            <a
              href="https://www.woodlandtrust.org.uk/newsletter/"
              className="nav-action-sm"
              role="button"
            >
              <img
                loading="lazy"
                alt="Newsletter"
                src="https://cdn1.accropress.com/Woodland%20Trust/images/newsletter-icon.svg"
              />
              Sign up to stay connected
            </a>
          </div>
        </div>

        <ul className="footer__social-links">
          <li className="footer__social-links__item">
            <a href="https://www.facebook.com/thewoodlandtrust" rel="external">
              <img
                loading="lazy"
                width="35px"
                height="35px"
                src="https://cdn1.accropress.com/Woodland%20Trust/images/facebook-white.svg"
                alt="facebook"
              />
            </a>
          </li>
          <li className="footer__social-links__item">
            <a href="https://twitter.com/woodlandtrust" rel="external">
              <img
                loading="lazy"
                width="35px"
                height="35px"
                src="https://cdn1.accropress.com/Woodland%20Trust/images/twitter-white.svg"
                alt="twitter"
              />
            </a>
          </li>
          <li className="footer__social-links__item">
            <a href="https://www.instagram.com/woodlandtrust/" rel="external">
              <img
                loading="lazy"
                width="35px"
                height="35px"
                src="https://cdn1.accropress.com/Woodland%20Trust/images/instagram-white.svg"
                alt="instagram"
              />
            </a>
          </li>
          <li className="footer__social-links__item">
            <a href="https://www.youtube.com/user/woodlandtrust" rel="external">
              <img
                loading="lazy"
                width="35px"
                height="35px"
                src="https://cdn1.accropress.com/Woodland%20Trust/images/youtube-white.svg"
                alt="youtube"
              />
            </a>
          </li>
        </ul>

        <ul className="footer__list">
          <li className="footer__list__item">
            <a
              className="footer__list__item--primary"
              href="https://www.woodlandtrust.org.uk/contact-us/"
              target=""
            >
              Contact us
            </a>
          </li>
          <li className="footer__list__item">
            <a
              className="footer__list__item--primary"
              href="https://jobs.woodlandtrust.org.uk/"
              target=""
            >
              Jobs
            </a>
          </li>
          <li className="footer__list__item">
            <a
              className="footer__list__item--primary"
              href="https://www.woodlandtrust.org.uk/press-and-media/"
              target=""
            >
              Press and media
            </a>
          </li>
          <li className="footer__list__item">
            <a
              className="footer__list__item--primary"
              href="https://www.woodlandtrust.org.uk/newsletter/"
              target=""
            >
              Newsletter
            </a>
          </li>
          <li className="footer__list__item">
            <a
              className="footer__list__item--primary"
              href="https://www.woodlandtrust.org.uk/publications/"
              target=""
            >
              Publications
            </a>
          </li>
        </ul>

        <ul className="footer__list">
          <li className="footer__list__item">
            <a
              className="footer__list__item--secondary"
              href="https://www.woodlandtrust.org.uk/accessibility/"
            >
              Accessibility
            </a>
          </li>
          <li className="footer__list__item">
            <a
              className="footer__list__item--secondary"
              href="https://www.woodlandtrust.org.uk/cookie-policy/"
              target=""
            >
              Cookie policy
            </a>
          </li>
          <li className="footer__list__item">
            <a
              className="footer__list__item--secondary"
              href="https://www.woodlandtrust.org.uk/privacy-policy/"
              target=""
            >
              Privacy policy
            </a>
          </li>
          <li className="footer__list__item">
            <a
              className="footer__list__item--secondary"
              href="https://www.woodlandtrust.org.uk/terms-and-conditions/"
              target=""
            >
              Terms and conditions
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div className="container">
      <div className="sub-footer">
        <div className="sub-footer__logo">
          <img
            loading="lazy"
            width="147px"
            height="46px"
            className="w-100"
            src="https://cdn1.accropress.com/Woodland%20Trust/images/frsb-logo.svg"
            alt="Fundraising Regulator Logo"
          />
        </div>

        <div className="row">
          <div className="sub-footer__text">
            <p>
              The Woodland Trust is a charity registered in England and Wales
              (No. 294344) and in Scotland (No. SC038885).&nbsp; A
              non-profit-making company limited by guarantee. Registered in
              England No. 1982873.
            </p>
            <p>
              Woodland Trust (Enterprises) Limited, registered in England (No.
              2296645), is a wholly owned subsidiary of the Woodland Trust.
              Registered office: Kempton Way, Grantham, Lincolnshire, NG31 6LL.
            </p>
            <p>
              The Woodland Trust and Woodland Trust Nature Detectives logos are
              registered trademarks. Images © protected Woodland Trust. VAT No.
              GB520 6111 04.
            </p>{" "}
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
